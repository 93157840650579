import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BlogContainer = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 40px auto;
  background: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 90%;
    padding: 25px;
    margin: 20px auto;
  }
`;

export const MainTitle = styled.h1`
  font-size: 2.8rem;
  color: #333;
  margin: 40px auto 20px;
  text-align: center;
  width: 90%;
  max-width: 900px;
  cursor: pointer;  // 커서 스타일 추가
  transition: color 0.2s;  // 호버 효과를 위한 전환 효과

  &:hover {
    color: #007bff;  // 호버 시 색상 변경 (원하는 색상으로 수정 가능)
  }

  @media (max-width: 768px) {
    font-size: 2.3rem;
    margin: 20px auto 10px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

export const BlogTitle = styled.p`
  font-size: 2.8rem;
  color: #333;
  margin: 40px auto 20px;
  text-align: center;
  width: 90%;
  max-width: 900px;
  cursor: pointer;  // 커서 스타일 추가
  transition: color 0.2s;  // 호버 효과를 위한 전환 효과

  &:hover {
    color: #007bff;  // 호버 시 색상 변경 (원하는 색상으로 수정 가능)
  }

  @media (max-width: 768px) {
    font-size: 2.3rem;
    margin: 20px auto 10px;
  }
`;

export const BlogSection = styled.section`
  margin: 2rem 0;
  line-height: 1.8;
  color: #444;
`;

export const SectionTitle = styled.h3`  // h2에서 h3로 변경
  font-size: 1.5rem;
  color: #333;
  margin: 1.5rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f0f0;
`;

export const PostList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PostCard = styled(Link)`
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s;
  display: block;

  &:hover {
    transform: translateY(-2px);
    background: #f0f0f0;
  }
`;

export const PostListTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
`;

export const PostTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

export const PostExcerpt = styled.p`
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
`;

export const PostDate = styled.span`
  color: #888;
  font-size: 0.9rem;
  display: block;
`;

export const BlogMedia = styled.div`
  width: 100%;
  margin: 2rem 0;
  text-align: center;
`;

export const MediaLink = styled.a`
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);

    &::after {
      content: '자세히 보기';
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.7);
      color: white;
      padding: 8px 16px;
      border-radius: 20px;
      font-size: 14px;
    }

    img {
      filter: brightness(0.9);
    }
  }

  img, video {
    max-width: 700px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    object-fit: contain;
    transition: filter 0.3s ease;
  }

  .gif-media {
    background: #f8f8f8;
  }

  @media (max-width: 768px) {
    img, video {
      max-width: 100%;
    }
  }
`;

export const ImageCaption = styled.p`
  color: #666;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
`;

export const RelatedPostsSection = styled.div`
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 2px solid #f0f0f0;
`;

export const RelatedPostsTitle = styled.h4`
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const RelatedPostsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 700px;

  td {
    padding: 12px 15px;
    border-bottom: 1px solid #f0f0f0;
  }

  a {
    color: #333;
    text-decoration: none;
    display: block;
    transition: color 0.2s;
    font-weight: bold;

    &:hover {
      color: #007bff;
    }
  }
`;

export const StyledLink = styled.a`
  color: #333;
  text-decoration: dotted underline;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    transform: scale(1.05);
  }
`;

export const ScrollTopButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
  opacity: ${props => props.visible ? '1' : '0'};
  visibility: ${props => props.visible ? 'visible' : 'hidden'};

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
  }
`;