import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BlogContainer, PostList, PostCard, PostListTitle, PostExcerpt, PostDate, MainTitle, SubTitle } from './BlogStyles';

const BlogMain = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dream_admin/posts`);
                setPosts(response.data);
            } catch (error) {
                console.error('Failed to fetch posts:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPosts();
    }, []);

    const handlePostClick = (slug) => {
        navigate(`/blog/${slug}`);
    };

    const handleTitleClick = () => {
        navigate('/blog');
    };
  
    if (loading) return <div>Loading...</div>;

    return (
        <>
            <Helmet>
                <title>몽픽 블로그 - 꿈과 현실의 이야기 | 몽픽(MongPick)</title>
                <meta name="description" content="몽픽 블로그에서 꿈해몽과 관련된 다양한 이야기를 만나보세요." />
                <meta name="keywords" content="몽픽, 꿈해몽, 꿈 해석, 로또번호, 꿈 분석" />
                <link rel="canonical" href="https://mongpick.com/blog" />
                <meta property="og:title" content="몽픽 블로그 - 꿈과 현실의 이야기" />
                <meta property="og:description" content="몽픽 블로그에서 꿈해몽과 관련된 다양한 이야기를 만나보세요." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://mongpick.com/blog" />
            </Helmet>

            <MainTitle onClick={handleTitleClick}>몽픽 블로그</MainTitle>
            <BlogContainer>
                <SubTitle>블로그 목록</SubTitle>
                <PostList>
                    {posts.map(post => (
                        <div 
                            key={post._id}
                            onClick={() => handlePostClick(post.slug)}
                            style={{ cursor: 'pointer' }}
                        >
                            <PostCard>
                                <PostListTitle>{post.title}</PostListTitle>
                                <PostExcerpt>{post.excerpt}</PostExcerpt>
                                <PostDate>{new Date(post.createdAt).toLocaleDateString()}</PostDate>
                            </PostCard>
                        </div>
                    ))}
                </PostList>
            </BlogContainer>
        </>
    );
};

export default BlogMain;