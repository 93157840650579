import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WritePost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const [slug, setSlug] = useState('');
    const [editorMode, setEditorMode] = useState('rich'); 
    const [isPreview, setIsPreview] = useState(false);
    const navigate = useNavigate();

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean'],
            ['code-block']
        ]
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('adminToken');
        
        if (!token) {
            alert('로그인이 필요합니다');
            navigate('/admin/dream/login');
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/dream_admin/post`, 
                {
                    title,
                    content,
                    excerpt,
                    slug,  // slug 추가
                    seo: {
                        title: `${title} | 몽픽 블로그`,
                        description: excerpt,
                        canonicalUrl: `https://mongpick.com/blog/${slug}`  // slug 사용
                    }
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            alert('포스트가 작성되었습니다');
            navigate('/blog');
        } catch (error) {
            if (error.response?.data?.error === 'duplicate_slug') {
                alert('이미 사용 중인 URL입니다');
            } else {
                alert('포스트 작성 실패');
            }
        }
    };

    return (
        <div style={{ 
            padding: '20px',
            margin: '20px',
            border: '1px solid black',
            backgroundColor: 'white' 
        }}>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                marginBottom: '20px'
            }}>
                <h1>블로그 포스트 작성</h1>
                <div>
                    <button 
                        onClick={() => setEditorMode(mode => mode === 'rich' ? 'html' : 'rich')}
                        style={{
                            padding: '8px 16px',
                            marginRight: '10px',
                            backgroundColor: '#0066cc',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        {editorMode === 'rich' ? 'HTML 모드' : '에디터 모드'}
                    </button>
                    <button 
                        onClick={() => setIsPreview(!isPreview)}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#0066cc',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        {isPreview ? '편집하기' : '미리보기'}
                    </button>
                </div>
            </div>

            {!isPreview ? (
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="제목"
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '10px',
                            border: '1px solid gray'
                        }}
                    />
                    <textarea
                        value={excerpt}
                        onChange={(e) => setExcerpt(e.target.value)}
                        placeholder="요약"
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '10px',
                            height: '100px',
                            border: '1px solid gray'
                        }}
                    />
                    <input
                        type="text"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        placeholder="URL 주소 (영문/숫자/-만 사용)"
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '10px',
                            border: '1px solid gray'
                        }}
                    />
                    <div style={{ height: '400px', marginBottom: '10px' }}>
                        {editorMode === 'rich' ? (
                            <ReactQuill
                                value={content}
                                onChange={setContent}
                                modules={modules}
                                style={{ height: '350px' }}
                            />
                        ) : (
                            <textarea
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                style={{
                                    width: '100%',
                                    height: '350px',
                                    padding: '10px',
                                    border: '1px solid gray',
                                    fontFamily: 'monospace',
                                    fontSize: '14px'
                                }}
                            />
                        )}
                    </div>
                    <button
                        type="submit"
                        style={{
                            padding: '10px 20px',
                            backgroundColor: 'blue',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        작성완료
                    </button>
                </form>
            ) : (
                <div className="preview" style={{ padding: '20px' }}>
                    <h1>{title}</h1>
                    <p style={{ color: '#666', marginBottom: '20px' }}>{excerpt}</p>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            )}
        </div>
    );
};

export default WritePost;