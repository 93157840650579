import React, { useState, useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; 
import './App.css';
import DreamForm from './components/DreamForm';
import LoadingScreen from './components/LoadingScreen';
import DreamResult from './components/DreamResult';
import DreamView from './components/DreamView';
import DreamBackground from './components/DreamBackground';
import ShareModal from './components/ShareModal'; 
import Blog from './components/blog/Blog';
import { ExternalLink } from 'lucide-react';
import { AppContainer, Title, Subtitle, ErrorMessage, BlogLink  } from './AppStyles';
import { dreamApiService } from './services/dreamApi';

import Login from './components/admin/Login';
import WritePost from './components/admin/WritePost';
import PrivateRoute from './components/admin/PrivateRoute';

// debounce 유틸리티 함수를 컴포넌트 외부로 이동
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(null, args), wait);
  };
};

function App() {
  const [stage, setStage] = useState('form');
  const [dreamInterpretation, setDreamInterpretation] = useState('');
  const [numberAnalysis, setNumberAnalysis] = useState('');
  const [dreamNumbers, setDreamNumbers] = useState([]);
  const [error, setError] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);  
  const [shareUrl, setShareUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);  // 추가: 중복 제출 방지

  const handleDreamSubmit = useCallback(async (dream) => {
    // 이미 제출 중이면 리턴
    if (isSubmitting) return;

    setIsSubmitting(true);
    setStage('loading');
    setError('');

    try {
      // 네트워크 상태 체크
      if (!navigator.onLine) {
        throw new Error('인터넷 연결을 확인해주세요.');
      }

      const data = await dreamApiService.interpretDream(dream);
      
      // 응답 데이터 유효성 검사
      if (!data || !data.dream_analysis || !data.number_analysis || !data.numbers) {
        throw new Error('올바르지 않은 응답 데이터입니다.');
      }

      setDreamInterpretation(data.dream_analysis);
      setNumberAnalysis(data.number_analysis);
      setDreamNumbers(data.numbers);
      setStage('result');
    } catch (error) {
      console.error('Dream interpretation error:', error);
      // 사용자에게는 친숙한 에러 메시지만 표시
      setError(
        error.message === '인터넷 연결을 확인해주세요.' 
          ? error.message 
          : '서버 오류입니다. 잠시 후 다시 시도해주세요.'
      );
      setStage('form');
    } finally {
      setIsSubmitting(false);
    }
  }, [
    isSubmitting, 
    setStage,
    setError,
    setDreamInterpretation,
    setNumberAnalysis,
    setDreamNumbers
  ]); // isSubmitting 의존성 추가

  // handleSaveAndShare를 일반 함수로 정의
  const saveAndShare = useCallback(async () => {
    if (!dreamInterpretation || !numberAnalysis || !dreamNumbers.length) return;
  
    try {
      const data = await dreamApiService.saveDream({
        dream_analysis: dreamInterpretation,
        number_analysis: numberAnalysis,
        numbers: dreamNumbers,
      });
      
      if (!data || !data.shareUrl) {
        throw new Error('공유 URL을 생성할 수 없습니다.');
      }
  
      setShareUrl(data.shareUrl);
      setShowShareModal(true);
    } catch (error) {
      console.error('Dream save error:', error);
      setError('저장 요청 실패. 잠시 후 다시 시도해주세요.');
    }
  }, [dreamInterpretation, numberAnalysis, dreamNumbers, setShareUrl, setShowShareModal, setError]);
  

  // debounced 버전 생성
  const handleSaveAndShare = useMemo(
    () => debounce(saveAndShare, 500),
    [saveAndShare]
  );

  const handleCloseShareModal = useCallback(() => {
    setShowShareModal(false);
    setShareUrl('');
  }, []);

  const handleReturnHome = useCallback(() => {
    // cleanup 함수
    const cleanup = () => {
      setStage('form');
      setDreamInterpretation('');
      setNumberAnalysis('');
      setDreamNumbers([]);
      setError('');
      setShowShareModal(false);
      setShareUrl('');
      setIsSubmitting(false);
    };

    // 즉시 실행하되, 안전하게 처리
    try {
      cleanup();
    } catch (error) {
      console.error('Cleanup error:', error);
      // 강제로 초기 상태로
      window.location.href = '/';
    }
  }, [
    setStage,
    setDreamInterpretation,
    setNumberAnalysis,
    setDreamNumbers,
    setError,
    setShowShareModal,
    setShareUrl,
    setIsSubmitting
  ]);

  return (
    <HelmetProvider>
      <Router>
        <DreamBackground />
        <BlogLink 
          href="/blog" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <ExternalLink size={16} />
          Blog
        </BlogLink>
        <AppContainer>
          {showShareModal && (
            <ShareModal 
              shareUrl={shareUrl}
              onClose={handleCloseShareModal}
            />
          )}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Title>몽픽 MongPick</Title>
                  <Subtitle>당신의 꿈에서 특별한 의미와 번호를 'Pick'하세요.</Subtitle>
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                  {stage === 'form' && (
                    <DreamForm 
                      onSubmit={handleDreamSubmit}
                      disabled={isSubmitting}
                    />
                  )}
                  {stage === 'loading' && <LoadingScreen />}
                  {stage === 'result' && (
                    <DreamResult 
                    interpretation={dreamInterpretation}
                    numberAnalysis={numberAnalysis}
                    numbers={dreamNumbers}
                    onSaveAndShare={handleSaveAndShare}
                    onReturnHome={handleReturnHome}
                  />
                  )}
                </>
              }
            />
            <Route path="/dream/:dreamId" element={<DreamView />} />
            <Route path="/blog/*" element={<Blog />} /> 

            {/* 관리자 라우트 추가 */}
            <Route
              path="/admin/dream/login"
              element={<Login />}
            />
            <Route
              path="/admin/dream/write"
              element={
                <PrivateRoute>
                  <WritePost />
                </PrivateRoute>
              }
            />
          </Routes>
        </AppContainer>
      </Router>
    </HelmetProvider>
  );
}

export default App;