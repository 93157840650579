import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { ChevronUp } from 'lucide-react';
import axios from 'axios';
import { BlogTitle, ScrollTopButton, BlogContainer } from './BlogStyles';
import { Helmet } from 'react-helmet-async';

const BlogPost = () => {
    const { slug } = useParams();  // postId 대신 slug 사용
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dream_admin/post/${slug}`);
                setPost(response.data);
            } catch (error) {
                console.error('Failed to fetch post:', error);
                navigate('/blog');
            } finally {
                setLoading(false);
            }
        };
        fetchPost();
    }, [slug, navigate]);

    useEffect(() => {
        const checkScrollTop = () => {
            setShowScroll(window.pageYOffset > 400);
        };

        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleTitleClick = () => {
        navigate('/blog');
    };

    if (loading) return <div>Loading...</div>;
    if (!post) return <Navigate to="/blog" replace />;

    return (
        <>
            <Helmet>
                <title>{post.seo.title}</title>
                <meta name="description" content={post.seo.description} />
                <link rel="canonical" href={post.seo.canonicalUrl} />
                <meta property="og:title" content={post.seo.title} />
                <meta property="og:description" content={post.seo.description} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={post.seo.canonicalUrl} />
            </Helmet>

            <BlogTitle onClick={handleTitleClick}>몽픽 블로그</BlogTitle>
            <BlogContainer>
                <h1>{post.title}</h1>
                <div className="post-meta">
                    <p>{new Date(post.createdAt).toLocaleDateString()}</p>
                </div>
                <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }} />
                <ScrollTopButton 
                    onClick={scrollToTop}
                    visible={showScroll}
                    aria-label="Scroll to top"
                >
                    <ChevronUp size={24} />
                </ScrollTopButton>
            </BlogContainer>
        </>
    );
};

export default BlogPost;